<template>
    <div class="">
        <div class="pb-10 h-100  pt-2 mb-15">

            <div class="start-overflow">
                <div class="create-company-container w-100 ">
                    <div v-if="basicInfo" class="main-div">
                        <div class="create-company-header d-flex align-items-center justify-content-between">
                            <span class="fs-14 ml-3 pl-3 text-white">Basic Information</span>
                            <span class="pointer" @click="showBasicInformation">
                                <i v-if="basicInformationTab" class="icon-chevron-double-up text-white"
                                    style="font-size: 20px"></i>
                                <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px;"></i>
                            </span>
                        </div>
                    </div>
                    <div :class="{'d-none':!basicInformationTab}" class="mt-6 px-10" >
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="w-100">
                                    <span class="input-label">First Name <span class="text-danger">*</span></span>
                                    <div class="d-flex align-content-center mt-2">
                                        <input type="text" maxlength="30" class="input-field" v-model="first_name"
                                           v-validate="{required:true,regex:/^[a-zA-Z0-9 _*]+$/}"
                                            placeholder="Enter First Name" name="first_name" />
                                    </div>
                                    <div class="text-right" >
                                        <span class="input-field-length">{{ 30 - first_name.length }}/30</span>
                                        <span class="float-left text-danger mb-3" v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="w-100">
                                    <span class="input-label">Middle Name</span>
                                    <div class="d-flex align-content-center mt-2">
                                        <input type="text" maxlength="30" class="input-field" v-model="middle_name"
                                            v-validate="{regex:/^[a-zA-Z0-9 _*]+$/}"  placeholder="Enter Middle Name"
                                            name="middle_name" />
                                    </div>
                                    <div class="text-right" >
                                        <span class="input-field-length">{{ 30 - middle_name.length }}/30</span>
                                        <span class="float-left text-danger mb-3" v-show="errors.has('middle_name')">Special characters not allowed
                                        </span>
                                    </div>

                                </div>

                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="w-100">
                                    <span class="input-label">Last Name <span class="text-danger">*</span></span>
                                    <div class="d-flex align-content-center mt-2">
                                        <input type="text" maxlength="30" class="input-field" v-model="last_name"
                                             v-validate="{required:true,regex:/^[a-zA-Z0-9 _*]+$/}"  placeholder="Enter Last Name" name="last_name" />
                                    </div>
                                    <div class="text-right" >
                                        <span class="input-field-length">{{30 - last_name.length }}/30</span>
                                        <span class="text-danger float-left mb-3 " v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
                                <div class="w-100">
                                    <span class="input-label">Date Of Birth( DD/MM/YY )<span class="text-danger fs-14">*</span></span>
                                    <div class="d-flex align-items-center mt-2">
                                        <date-picker
                                            class=" profile-date-picker calendar-edit-per input-field pt-0 pb-0 pr-0"
                                            v-model="dateofbirth" value-type="format" lang="en"
                                            :clearable="false" style="padding-left: 0px !important;height: 38px !important;"
                                            :not-after="disable_start_year" :default-value="disable_start_year"
                                            :editable="false" placeholder="DD/MM/YYYY" name="dateofbirth" v-validate="'required'"
                                            type="date" format="DD/MM/YYYY">
                                        </date-picker>
                                    </div>
                                    <span class=" text-danger" v-show="errors.has('dateofbirth')" style="text-transform: none !important;">Date of Birth
                                        is required</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
                                <div class="w-100">
                                    <span class="input-label">Blood</span>
                                    <div class="d-flex align-items-center mt-2">
                                        <multiselect class="diginew-multiselect" name="blood_type_options"
                                            :searchable="true" :options="blood_type_options" v-model="blood_type"
                                            :show-labels="false" placeholder="Select Blood Type">
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">Blood type is not found</div>
                                            </template>

                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
                                <span class="input-label">Gender <span class="text-danger">*</span></span>
                                <div class="d-flex align-items-center ml-4 mt-4">
                                    <div class="d-flex align-items-center">
                                        <input v-validate="'required'" type="radio" id="gender_female" name="gender" v-model="gender" value="Female" checked = "checked">
                                        <label class="ml-2 mb-0" for="gender_female">Female</label>
                                    </div>
                                    <div class="d-flex align-items-center ml-6">
                                        <input v-validate="'required'" type="radio" id="gender_male" name="gender" v-model="gender" value="Male">
                                        <label class="ml-2 mb-0" for="gender_male">Male</label><br>
                                    </div>
                                    <div class="d-flex align-items-center ml-6">
                                        <input v-validate="'required'" type="radio" id="custom_gender" name="custom_gender" v-model="gender" value="Custom">
                                        <label class="ml-2 mb-0" for="custom_gender">Custom</label><br>
                                    </div>
                                </div>
                                <div v-if="custom_gender_active" class="w-100 mt-2 pr-10 pl-4"
                                    style="position: relative;">
                                    <input name="gender"
                                        v-validate="'required'" type="text"
                                        v-model="custom_gender"
                                        style="border-bottom: solid 0.5px #cdd2da !important; background: transparent; width: 100%;">
                                </div>
                                 <span class=" text-danger" v-show="errors.has('gender')" style="text-transform: none !important;">Gender
                                is required</span>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <span class="input-label">User Access <span class="text-danger">*</span></span>
                                <div class="d-flex align-items-center ml-4 mt-2">
                                    <div v-if="loggedInUser.user_role == 'owner'" class="d-flex align-items-center">
                                        <input type="radio" v-validate="'required'" id="super_super_admin" v-model="user_access" name="user_access" value="super_admin">
                                        <label class="ml-2 mb-0" for="super_super_admin" selected>Super Admin</label>
                                    </div>
                                    <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="d-flex align-items-center ml-6">
                                        <input type="radio" id="user_admin" v-model="user_access" name="user_access" value="admin" v-validate="'required'">
                                        <label class="ml-2 mb-0" for="user_admin">Admin </label><br>
                                    </div>
                                    <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'admin'" class="d-flex align-items-center ml-6">
                                        <input type="radio" id="user_user" v-validate="'required'" v-model="user_access" name="user_access" value="employee">
                                        <label class="ml-2 mb-0" for="user_user">User</label><br>
                                    </div>
                                </div>
                                <span class=" text-danger" v-show="errors.has('user_access')" style="text-transform: none !important;">User Access
                                        are required</span>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100">
                                    <span class="input-label">Business Units <span class="text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2">
                                        <multiselect v-validate="'required'" class="diginew-multiselect filterName"
                                            name="business_units_type" :searchable="true" :options="business_units_type" @select="deptChange()"
                                            v-model="business_units" label="name" :show-labels="false" @open="getBusinessUnitOptions" :loading="business_unit_loading" placeholder="Select Business Units">
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600" selected>Bussiness units are not
                                                    Found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <span class=" text-danger" v-show="errors.has('business_units_type')" style="text-transform: none !important;">Business Units
                                        are required</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100">
                                    <span class="input-label">Branch <span class="text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2">
                                        <multiselect class="diginew-multiselect" v-validate="'required'"
                                            name="branch_type_options" @open="getBranchOptions" :loading="branch_loading" :searchable="true"
                                            :options="branch_type_options" label="name" :show-labels="false" v-model="branch" placeholder="Select Branch" >
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">Branch is not found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>

                                    </div>
                                </div>
                                <span class=" text-danger" v-show="errors.has('branch_type_options')" style="text-transform: none !important;">Branch is
                                    required</span>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100">
                                    <span class="input-label">Country <span class="text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2">
                                        <multiselect v-validate="'required'" class="diginew-multiselect"
                                            name="country" :show-labels="false" :searchable="true" :options="countries"
                                            v-model="country" placeholder="Select Country">
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">Countries are not found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>

                                </div>
                                <span class=" text-danger mb-3" v-show="errors.has('country')" style="text-transform: none !important;">Country is
                                    required</span>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100">
                                    <span class="input-label">Department <span class="text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2">
                                        <multiselect v-validate="'required'" class="diginew-multiselect" :disabled ="business_units == ''"
                                            name="department_type" label="name" :show-labels="false" :searchable="true" :loading="department_unit_loading" @open="getDepartmentUnitOptions" :options="department_type"
                                            v-model="department" placeholder="Select Departments">

                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">Departments are not found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                                <span class=" text-danger mb-3" v-show="errors.has('department_type')" style="text-transform: none !important;">Department is
                                    required</span>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100">
                                    <span class="input-label">Reporting Manager</span>
                                    <div class="d-flex align-items-center mt-2">
                                        <multiselect class="diginew-multiselect"
                                            name="reporting_manager_type" :loading="reporting_manager_loading" :show-labels="false" :searchable="true"
                                            :options="reporting_manager_type" label="full_name" @open="getReportingManagerOptions" v-model="reporting_manager"
                                            placeholder="Select Reporting Manager">

                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">Reporting Manager is not found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>

                                </div>
                                <span class=" text-danger mb-3" v-show="errors.has('reporting_manager_type')" style="text-transform: none !important;">Reporting Manager is
                                    required</span>
                            </div>

                            <!--div class="col-lg-4 col-md-6 col-sm-12 mt-4">
                                <div class="w-100">
                                    <span class="input-label">Employment Id*</span>
                                    <div class="d-flex align-content-center mt-2">
                                        <input id="username" style="display:none !important;" type="email" name="fakeusernameremembered">
                                        <input type="text" maxlength="30" autocomplete="nope" class="input-field" v-model="employement_id"
                                            v-validate="'required|alpha_num'" placeholder="Enter Employment Id"
                                            name="employement_id" />
                                    </div>
                                    <div class="text-right" >
                                        <span class="input-field-length">{{ employement_id.length }}/100</span>
                                        <span class="text-danger float-left" v-show="errors.has('employement_id')"  style="text-transform: none !important;">
                                            {{ errors.first('employement_id') }}
                                        </span>

                                    </div>
                                </div>

                            </div-->
                            <!-- <div class="col-lg-4 col-md-6 col-sm-12 mt-3">
                                <div class="w-100">
                                    <span class="input-label">Password*
                                        <sup>
                                            <el-tooltip class="item" effect="dark" placement="top-start">
                                                <template slot="content">
                                                    Password must contain the following
                                                    <br>1. At-least one uppercase letter
                                                    <br>2. At-least one digit
                                                    <br>3. At-least one special character
                                                    <br>4. Minimum 8 characters
                                                </template>
                                                <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                            </el-tooltip>
                                        </sup>
                                    </span>
                                    <div class="d-flex align-content-center " style="position: relative;margin-top: .7rem !important;">
                                        <input :type="password_type" maxlength="30" id="password-auto" autocomplete="new-password" class="input-field" v-model="password" v-validate="'required'"
                                            placeholder="Enter Password " name="password" @input="onPassInput" />
                                        <span style="position: absolute; right: 10px; top: 10px;">
                                            <img class="pointer" v-if="password_type == 'password'" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/hide-password.svg" @click="changePassVisibility('show_pass')" alt="eye" style="width: 20px;">
                                            <img class="pointer" v-if="password_type == 'text'" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/show-password.svg" @click="changePassVisibility('hide_pass')"
                                                alt="eye" style="width: 20px;">
                                        </span>
                                    </div>
                                </div>
                                <password-meter :pwd="password" id="password" style="display:none"></password-meter>
                                <span class="text-danger" v-show="errors.has('password')"  style="text-transform: none !important;">Password id is required</span>
                            </div> -->
                            <!-- <div class="col-lg-4 col-md-6 col-sm-12 mt-3">
                                <div class="w-100">
                                    <span class="input-label">Delivered to which User E-mail?*</span>
                                    <div class="d-flex align-items-center ml-4 mt-6">
                                        <div class="d-flex align-items-center">
                                            <input type="radio" id="personal_email" v-model="is_deliver_to" name="personal_email" selected value="Personal Email">
                                            <label class="ml-2 mb-0" for="personal_email">Person E-mail</label>
                                        </div>
                                        <div class="d-flex align-items-center ml-6">
                                            <input type="radio" id="business_email" v-model="is_deliver_to" name="business_email" value="Business Email">
                                            <label class="ml-2 mb-0" for="business_email">Business E-mail </label><br>
                                        </div>
                                    </div>

                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="create-company-container w-100">
                    <div class="mt-9 pb-6 px-10">
                        <div class="create-company-header d-flex align-items-center justify-content-between">
                            <span class="fs-14 text-white">Communication Info </span>
                            <span class="pointer" @click="showCommunicationInformation">
                                <i v-if="communicationInformationTab" class="icon-chevron-double-up text-white"
                                    style="font-size: 20px"></i>
                                <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px;"></i>
                            </span>
                        </div>
                        <div :class="{'d-none':!communicationInformationTab}" class="row mt-6">
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="w-100">
                                    <span class="input-label">Personal E-mail 
                                        <span class="text-danger">*</span>
                                        <sup>
                                            <el-tooltip class="item" effect="dark" placement="top-start">
                                                <template slot="content">
                                                    We will send you an email with the login details.
                                                </template>
                                                <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                            </el-tooltip>
                                        </sup>
                                    </span>
                                    <div class="d-flex align-content-center mt-2">
                                        <input type="email" maxlength="230" class="input-field" v-model="personal_email"
                                            placeholder="Enter Personal Email" v-validate="'required'"
                                            name="personal_email" />
                                    </div>
                                    <div class="text-right" >
                                        <span class="input-field-length">{{230 - personal_email.length }}/230</span>
                                        <span class="text-danger float-left" v-show="errors.has('personal_email')"  style="text-transform: none !important;">Personal E-mail is required</span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="w-100">
                                    <span class="input-label">Phone Number <span class="text-danger">*</span></span>
                                    <div class="d-flex align-items-center  mt-2">
                                        <div class="leftType ml-0">
                                            <div class="d-flex">
                                                <multiselect v-validate="'required'" v-model="primary_phone_type"
                                                    class="diginew-multiselect phone-number pointer icon_space" id="ajax" :options="primary_phone_type_options"
                                                    :multiple="false" :searchable="true" :internal-search="true"
                                                    :show-labels="false" :clear-on-select="false"
                                                    defaultCountry="IN" style="width: 70px !important;height: 38px !important;"
                                                    :onlyCountries="['IN']" placeholder=""
                                                    :close-on-select="true" :options-limit="280" :max-height="200"
                                                    :show-no-results="true" :hide-selected="false">
                                                    <template slot="singleLabel" slot-scope="props">
                                                        <img class="option__image" :src="props.option.image" style="width:22px">
                                                    </template>
                                                    <template slot="option" slot-scope="{ option }">
                                                        <div class="d-flex flex-row">
                                                            <img :src="option.image" style="width: 10px;" />
                                                            <span class="ml-4">
                                                                <span style="font-size: 14px">{{ option.name }}</span>
                                                            </span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="profile-primay-phone ml-2 w-100" style="position: relative;">
                                            <div>
                                                <vue-tel-input name="primary_phone" data-vv-as="Phone Number" :enabledCountryCode="false"
                                                    :disabledFormatting="false" @onInput="phoneNumber" :validCharactersOnly="true" :maxLen="15"
                                                    class="teleContact w-100" ref="telInput"
                                                    :defaultCountry="primary_phone_number.country_code"
                                                    placeholder="Enter Primary Phone Number"
                                                    v-validate="{required:true, min:12}"
                                                    v-model="primary_phone_number.phone_number">
                                                </vue-tel-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <span class="text-danger" v-show="errors.has('primary_phone')"  style="text-transform: none !important;">{{errors.first('primary_phone')}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="create-company-container w-100">
                    <div class="pb-10 px-10">
                        <div class="create-company-header d-flex align-items-center justify-content-between">
                            <span class="fs-14 text-white">Login Info </span>
                            <span class="pointer" @click="showLoginInformation">
                                <i v-if="loginInformationTab" class="icon-chevron-double-up text-white"
                                    style="font-size: 20px"></i>
                                <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px;"></i>
                            </span>
                        </div>
                        <div :class="{'d-none':!loginInformationTab}" class="row mt-6">
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="w-100">
                                    <span class="input-label">E-mail ID <span class="text-danger">*</span></span>
                                    <div class="d-flex align-content-center mt-2 w-100">
                                        <div class="d-flex align-items-center field-data w-100">
                                            <input id="username" class="d-none" style="display:none !important;" type="email" name="fakeusernameremembered">
                                            <input type="text"
                                                class="form-control-email-dropdown w-100"
                                                v-model="business_email"
                                                autocomplete="nope"
                                                name="login_email"
                                                v-validate="{required: true,regex: /^[a-zA-Z0-9-_.]+$/}"
                                                maxlength="230"
                                                placeholder="Enter E-mail ID" />
                                            <span class="input-group-addon-email" style="font-size: 14px;">@</span>
                                            <el-select v-validate="{required: true}" v-model="domain_name" name="domain_name" class="" style="padding: 0 !important;border-color: rgb(215 218 226 / 0%) !important" >
                                                <el-option
                                                    v-for="(domain, index) in domain_name_list"
                                                    :key="index"
                                                    :label="domain"
                                                    :value="domain">
                                                </el-option>
                                            </el-select>
                                            <!--span class="fs-14" style="">
                                                {{company_domain_name.replace(/.+\/\/|www.|\+/g, '')}}
                                            </span-->
                                        </div>
                                    </div>
                                    <div class="text-right">
                                        <span class="input-field-length" style="width: 60px;">{{230 - business_email.length }}/230</span>
                                        <span class="text-danger float-left" v-show="errors.has('login_email')"  style="text-transform: none !important;">
                                            {{errors.first('login_email')}}
                                        </span>
                                    </div>
                                    <div class="text-right">
                                        <span class="text-danger text-place float-right" v-show="errors.has('domain_name')"  style="text-transform: none !important;padding-right: 14px">
                                            Domain is required
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="w-100">
                                    <span class="input-label">Password <span class="text-danger">*</span>
                                        <!-- <sup>
                                            <el-tooltip class="item" effect="dark" placement="top-start">
                                                <template slot="content">
                                                    Password must contain the following
                                                    <br>1. At-least one uppercase letter
                                                    <br>2. At-least one digit
                                                    <br>3. At-least one special character
                                                    <br>4. Minimum 8 characters
                                                </template>
                                                <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                            </el-tooltip>
                                        </sup> -->
                                    </span>
                                    <div class="d-flex align-content-center mt-2" style="position: relative;">
                                        <input id="password" class="d-none" style="display: none !important;" type="password" name="fakeusernameremembered">
                                        <input :type="password_type" style="padding-right: 40px !important;" maxlength="30" id="password-auto" autocomplete="nope" class="input-field" v-model="password" v-validate="'required'"
                                            placeholder="Enter Password " name="password" @input="onPassInput" />
                                        <span style="position: absolute; right: 10px; top: 10px;">
                                            <img class="pointer" v-if="password_type == 'password'" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/hide-password.svg" @click="changePassVisibility('show_pass')" alt="eye" style="width: 20px;">
                                            <img class="pointer" v-if="password_type == 'text'" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/show-password.svg" @click="changePassVisibility('hide_pass')"
                                                alt="eye" style="width: 20px;">
                                        </span>
                                    </div>
                                </div>
                                <password-meter :pwd="password" id="password" style="display:none"></password-meter>
                                <span class="text-danger" v-show="errors.has('password')"  style="text-transform: none !important;">Password id is required</span>
                            </div>
                        </div>
                        <div class="mt-9 mb-1">
                            <div class="d-flex align-items-center justify-content-center" v-if="basicInfo">
                                <button class="btn btn-outline-secondary" :disabled="basicInfo_loader" @click="cancel" style="width: 120px;border-radius: 5px;">Cancel</button>
                                <button class="btn btn-new-secondary ml-5" :disabled="basicInfo_loader" @click="nextPermissionProduct" style="width: 120px;border-radius: 5px;">Next
                                    <hollow-dots-spinner v-if="basicInfo_loader" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <permission-product :comp_data="comp_data" v-if="permissionProduct"></permission-product>
        <!--create-success v-if="create_success" modal_name="create_success"></create-success-->
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
    </div>

</template>

<script>
    //import CreateSuccess from './CreateSuccess.vue'
    import DatePicker from 'vue2-datepicker'
    import Password from "vue-password-strength-meter";
    import PasswordMeter from "../PasswordMeter";
    import PermissionProduct from "./PermissionProduct";
    import bloodgroup from '../json/bloodgroup.json';
    import countries from '../json/countries.json';
    import companies from '../mixins/companies';
    import { SweetModal } from 'sweet-modal-vue';
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    export default {
        components: { HalfCircleSpinner },
        mixins: [companies],
        props: ['comp_data'],
        data() {
            return {
                warning_msg: '',
                first_name: '',
                middle_name: '',
                company_domain_name: '',
                domain_name:'',
                last_name: '',
                branch: '',
                blood_type: '',
                branch_type_options: [],
                blood_type_options: bloodgroup,
                department: '',
                department_type: [],
                reporting_manager: '',
                reporting_manager_type: [],
                business_units: '',
                business_units_type: [],
                //employement_id: '',
                symbol: '@',
                dateofbirth: '',
                password: '',
                password_type: 'password',
                gender: '',
                custom_gender: '',
                custom_gender_active: false,
                user_access: '',
                //customAccess:false,
                email: '',
                personal_email: '',
                business_email: '',
                basicInfo: true,
                countries: countries,
                country: '',
                is_deliver_to: 'Personal Email',
                permissionProduct:false,
                basicInformationTab: true,
                permissionProductTab: false,
                isLoading: {
                    branch_type_options: false,
                    blood_type_options: false,
                    department_type: false,
                    reporting_manager_type: false,
                    business_units_type: false,
                },
                primary_phone_type: {
                    name: 'Work',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                },
                primary_phone_type_options: [
                    {
                        name: 'Work',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                    },
                    {
                        name: 'Home',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/home-contact-icon.svg'
                    },
                    {
                        name: 'Mobile',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/mobile-contact-icon.svg'
                    },
                    {
                        name: 'Fax',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/fax-contact-icon.svg'
                    },
                    {
                        name: 'Others',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/others-contact-icon.svg'
                    },
                    {
                        name: 'Whatsapp',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/whatsapp-contact-icon.svg'
                    },
                    {
                        name: 'Line',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/line-contact-icon.svg'
                    },
                    
                    {
                        name: 'Telegram',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/telegram-contact-icon.svg'
                    },
                    
                    {
                        name: 'Messenger',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/messenger-contact-icon.svg'
                    },
                    
                ],
                primary_phone_number:{
                    national: "",
                    country_code: "US",
                    dial_code: "1",
                    phone_number: "",
                    phone_type: "mobile_number"
                },
                about: '',
                skip: 0,
                limit: 10,
                search_key: "",
                branch_loading: false,
                business_unit_loading: false,
                department_unit_loading: false,
                reporting_manager_loading: false,
                communicationInformationTab: true,
                loginInformationTab: true,
                domain_name_list:[],
                exists:false,
                disable_start_year: new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
                // comp_data: {}
                basicInfo_loader: false
            }
        },
        methods: {
            deptChange(){
                this.department = '';
            },
            phoneNumber({number,isValid,country}) {
                this.primary_phone_number.dial_code = country.dialCode
                this.primary_phone_number.country_code = country.iso2
                this.primary_phone_number.national = number.national
            },
            onPassInput(e) {
                if (password) {
                    $('#password').slideDown('slow')
                    document.getElementById("password").style.display = "flex"
                } else {
                    $('#password').slideUp('slow')
                    document.getElementById("password").style.display = "none"
                }
                if (e.target.value.match(/^(?=.{8,}$)/)) {
                    this.min_pwd_length = true;
                } else {
                    this.min_pwd_length = false;
                }
                if (e.target.value.match(/^(?=.*[A-Z])/)) {
                    this.is_uppercase_pwd = true;
                } else {
                    this.is_uppercase_pwd = false;
                }
                if (e.target.value.match(/^(?=.*[0-9])/)) {
                    this.is_number_pwd = true;
                } else {
                    this.is_number_pwd = false;
                }
                if (e.target.value.match(/^(?=.*[!@#$%^&*()])/)) {
                    this.is_special_character_pwd = true;
                } else {
                    this.is_special_character_pwd = false;
                }
            },
            showBasicInformation () {
                this.basicInformationTab = !this.basicInformationTab;
            },
            showCommunicationInformation () {
                this.communicationInformationTab = !this.communicationInformationTab;
            },
            showLoginInformation() {
                this.loginInformationTab = !this.loginInformationTab;
            },
            changePassVisibility(type) {
                if (type == 'show_pass') {
                    this.password_type = "text";
                } else if (type == 'hide_pass') {
                    this.password_type = "password";
                }
            },

            cancel() {
                this.$router.push('/employee/listemployee/cmpid/'+this.$route.params.company_id);
            },

            nextPermissionProduct() {
                this.basicInfo_loader = true
                this.checkEmailId()
                setTimeout(() => {
                    if(this.exists == true){
                        this.warning_msg = "Email Id is already Exists"
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    } else{
                        this.$validator.validateAll().then(result => {
                            if(result ){
                                localStorage.setItem("employee_data", JSON.stringify(this.create_data));
                                this.$emit('switchWizard');
                            }
                        })
                    }
                    this.basicInfo_loader = false
                }, 3000);
            },
            async checkEmailId() {
                let email = this.business_email.concat(this.symbol);
                let id = email.concat(this.domain_name);
                try {
                    let params = {
                        login_id: id
                    }
                    let response = await this.checkEmailExist(params);
                    this.exists = response.exists;
                    this.$forceUpdate()
                }
                catch(err) {
                    console.log(err);
                }
            },
            async getBranchOptions() {
                this.branch_type_options = [];
                this.branch_loading = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        company_id: this.$route.params.company_id
                    }
                    let response = await this.getListBranch(params.skip, params.limit, params.company_id);
                    if(response.status_id == 1) {
                        let branch_data = response.response;
                        branch_data.forEach((branch, index) => {
                            this.branch_type_options.push({
                                id: branch.id,
                                name: branch.name
                            })
                        });
                    }
                    this.branch_loading = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            async getDomain() {
                this.domain_name_list = [];
                try {
                    let params = {
                        company_id: this.$route.params.company_id
                    }
                   let response = await this.getDomainList(params);
                    if(response.status_id == 1) {
                        this.domain_name_list = response.response;

                    }
                    this.business_unit_loading = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            async getBusinessUnitOptions() {
                this.business_units_type = [];
                this.business_unit_loading = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key,
                        company_id: this.$route.params.company_id
                    }
                    let response = await this.getListBusinessUnit(params);
                    if(response.status_id == 1) {
                        let bus_data = response.response;
                        bus_data.forEach((business_unit, index) => {
                            this.business_units_type.push({
                                id: business_unit.id,
                                name: business_unit.name
                            })
                        });
                    }
                    this.business_unit_loading = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            async getDepartmentUnitOptions() {
                this.department_type = [];
                this.department_unit_loading = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key,
                        company_id: this.$route.params.company_id,
                        business_unit_id:this.business_units.id
                    }
                    let response = await this.getDepartment(params);
                    if(response.status_id == 1) {
                        let department_data = response.response;
                        department_data.forEach((department_unit, index) => {
                            this.department_type.push({
                                id: department_unit.id,
                                name: department_unit.name
                            })
                        });
                    }
                    this.department_unit_loading = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            async getReportingManagerOptions() {
                this.reporting_manager_loading = true;
                this.reporting_manager_type = [];
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key,
                        company_id: this.$route.params.company_id
                    }
                    let response = await this.getListEmployees(params);
                    if(response.status_id == 1) {
                        let reporting_manager_data = response.response;
                        reporting_manager_data.forEach((reporting_manager, index) => {
                            this.reporting_manager_type.push({
                                user_id: reporting_manager.user_id,
                                full_name: reporting_manager.full_name
                            })
                        });
                    }
                    this.reporting_manager_loading = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            async getCompData() {
                try {
                    let response = await this.getCompanyById(this.$route.params.company_id);
                    if (response.status_id == 1) {
                        let res_data = response.response
                        this.comp_data = res_data
                    }
                }
                catch(err) {
                    // console.log(err)
                }
            },
        },
        mounted() {
            // this.getCompData();
            setTimeout(() => {
                $(document).ready(function() {
                    $('#password-auto').attr('autocomplete', 'off');
                    setTimeout(() => {
                        this.emailExists = false
                    }, 1000);
                });
            }, 500);
            this.company_domain_name = localStorage.getItem("company_domain_name");
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            create_data() {
                return {
                    first_name: this.first_name,
                    middle_name: this.middle_name,
                    last_name: this.last_name,
                    date_of_birth: this.dateofbirth ? this.dateofbirth.split("/")[2]+"-"+this.dateofbirth.split("/")[1]+"-"+this.dateofbirth.split("/")[0] : "",
                    blood_group: this.blood_type,
                    gender: this.gender,
                    custom_gender: this.custom_gender,
                    country: this.country,
                    branch_id: this.branch.id,
                    user_access: this.user_access,
                    business_unit_id: this.business_units.id,
                    department_id: this.department.id,
                    reporting_manager: this.reporting_manager ? this.reporting_manager.user_id : "",
                    password: this.password,
                    // password_deliver_to: this.is_deliver_to,
                    personal_email: this.personal_email,
                    business_email: this.business_email+'@'+this.domain_name.replace(/.+\/\/|www.|\+/g, ''),
                    company_id: this.$route.params.company_id,
                    primary_phone: {
                        phone_type: this.primary_phone_type.name,
                        phone_number: this.primary_phone_number.national,
                        dial_code: this.primary_phone_number.dial_code,
                        country_code: this.primary_phone_number.country_code
                    },
                    products: []
                }
            }
        },
        watch: {
            gender: function(val) {
                if(val == 'Custom') {
                    this.custom_gender_active = true;
                } else {
                    this.custom_gender_active = false;
                    this.custom_gender = '';
                }
            }
        },
        created() {
            this.getDomain();
            const dict = {
                custom: {
                    first_name: {
                        required: () => "First Name is required",
                        regex: () => "Special characters not allowed",
                    },
                    last_name: {
                        required: () => "Last Name is required",
                        regex: () => "Special characters not allowed",
                    },
                    gender: {
                        required: () => "Gender is required",
                    },
                    // personal_email: {
                    //     required: () => "Personal Email Address is required",
                    //     email: () => "Please enter valid Email Address",
                    // },

                    login_email:{
                        required: () => "E-mail id is required",
                        regex: () => "Please enter valid Email Address",
                    },
                    phone_number: {
                        required: () => "Phone Number is required",
                        min: () => "Phone Number must contain more than 9 numbers",
                    },
                    password: {
                        required: () => "Password is required",
                        min: () => "Password must contain minimum 8 characters",
                        regex: () => "Password is Invalid",
                    },
                }
            };
            this.$validator.localize("en", dict);
        },
        components: {
            DatePicker,
            Password,
            PasswordMeter,
            PermissionProduct,
            SweetModal
        },
    }
</script>
<style>
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .main-div {
        background: #fff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 6px;
    }

    .overflow-auto {
        overflow: auto;
    }


    .change-pic {
        position: absolute;
        bottom: -10px;
        width: 100%;
    }

    .edit-image {
        position: absolute;
        right: 0;
        bottom: 5px;
    }

    .change-cover-pic {
        padding: 3.5px 10px;
        border-radius: 50px;
        -webkit-backdrop-filter: blur(1.5px);
        backdrop-filter: blur(1.5px);
        background-color: rgba(114, 100, 100, 0.2);
        color: #fff;
    }

    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }

    .input-label {
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.39px;
        text-align: left;
        color: #0475f3;
        padding: 0 0 6px 10px;
    }

    .input-field {
        border-radius: 6px !important;
        border: solid 0.5px #3f3f3f;
        background-color: #fff;
        padding: 8px 10px;
        width: 100% !important;
        height: 38px;
    }

    .input-field-length {
        opacity: 0.5;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.3px;
        color: #000;
    }
    .field-data{
        border-radius: 6px !important;
        border: solid 0.5px #3f3f3f;
        background-color: #fff;
        padding: 8px 10px;
        width: 100% !important;
        height: 38px !important
    };

    .btn-new-cancel {
        color: #fff;
        background-color: #d51650;
        border-radius: 5px;
        padding-left: 35px;
        padding-right: 35px;
        text-transform: none;
        font-weight: 500;
        border: 2px solid #d51650;
        line-height: 30px;
        box-shadow: none;
    }
    .unfinishedCircle {
        background: #e8e8e8;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.32);
        font-weight: 500;
    }

    .finishedCircle {
        background: #128807;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }

    .selectedCircle {
        background: #ff9a06;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }

    .finishedCircleText {
        position: absolute;
        font-size: 14px;
        color: #303031;
        margin-top: 6em;
        font-weight: 600;
    }

    .unfinishedCircleText {
        position: absolute;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.32);
        margin-top: 6em;
        font-weight: 600;
    }

    .unfinishedDash {
        color: #e8e8e8;
        font-size: 20px;
        border-top: 2px dashed rgba(0, 0, 0, 0.32);
        width: 25%
    }

    .finishedDash {
        color: #128807;
        font-size: 20px;
        border: 1px dashed #128807;
        width: 100px;
    }

    .mx-input {
        display: inline-block;
        height: 0px !important;
    }
    .mx-input-wrapper{
        padding :3px 0px !important
    }

</style>